import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import StudioPerksList from "../StudioPerksList"
import CareerCard from "../CareerCard"
import LinkWithIcon from "../LinkWithIcon"
import Icon from "../Icon"

const GetStudioPerks = () => {
  const { datoCmsPage } = useStaticQuery(graphql`
    query GetStudioPerks {
      datoCmsPage(id: { eq: "DatoCmsPage-21704681-en" }) {
        blades {
          ... on DatoCmsStudioPerksList {
            heading
            perks {
              icon {
                url
              }
              heading
              details
            }
          }
        }
      }
    }
  `)

  return (datoCmsPage?.blades || [])?.find(
    (blade) => blade?.__typename === "DatoCmsStudioPerksList"
  )
}

const CareerBody = ({
  description,
  requirements,
  // experience,
  benefits,
  interested,
  application_url: applicationUrl,
  relatedCareers = [],
}) => {
  const studioPerks = GetStudioPerks()

  const handleSocialClick = (e) => {
    e.preventDefault()
    const url = e?.currentTarget?.href
    const w = 600
    const h = 400

    if (url) {
      window.open(
        url,
        "_blank",
        `
                scrollbars=yes,
                width=${w}, 
                height=${h}, 
                top=${window?.innerHeight / 2 - h / 2}, 
                left=${window?.innerWidth / 2 - w / 2}
            `
      )
    }
  }

  return (
    <>
      <div className="py-12 md:py-16 xl:py-28 bg-white relative">
        <div className="container mx-auto relative px-10">
          <div className="grid grid-cols-1 md:grid-cols-14">
            <div
              className={`col-span-full ${
                relatedCareers.length > 0
                  ? "lg:col-span-8 xl:col-span-7"
                  : "xl:col-span-12"
              } xl:col-start-2`}
            >
              {description && (
                <div>
                  <h2 className="mb-4 text-xl font-bold">Description</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: description }}
                    className="wysiwyg"
                  />
                </div>
              )}
              {requirements && (
                <div className="mt-8 md:mt-12">
                  <h2 className="mb-4 text-xl font-bold">Requirements</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: requirements }}
                    className="wysiwyg"
                  />
                </div>
              )}
              {/* {experience && (
                                <div className="mt-8 md:mt-12">
                                    <h2 className="mb-4 text-xl font-bold">Experience</h2>
                                    <div dangerouslySetInnerHTML= {{ __html: experience }} className="wysiwyg" />
                                </div>
                            )} */}
              {benefits && (
                <div className="mt-8 md:mt-12">
                  <h2 className="mb-4 text-xl font-bold">Benefits</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: benefits }}
                    className="wysiwyg"
                  />
                </div>
              )}
              <LinkWithIcon
                to={applicationUrl}
                external
                icon="arrowRight"
                color="mi-dark"
                text="Apply"
                className="mt-8 md:mt-12"
              />
              <div className="hidden xl:flex text-black mt-8 md:mt-12 pt-8 md:pt-12 border-t border-dashed border-aldo">
                <span className="hidden md:inline-block">
                  Share this opportunity
                </span>
                {typeof window !== "undefined" && (
                  <a
                    href={`https://twitter.com/intent/tweet?url=${window?.location?.href}`}
                    onClick={(e) => handleSocialClick(e)}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="md:ml-4 lg:ml-6 w-6"
                  >
                    <Icon name="twitter" />
                    <span className="hidden">Twitter</span>
                  </a>
                )}
                {typeof window !== "undefined" && (
                  <a
                    href={`https://www.facebook.com/sharer.php?u=${window?.location?.href}`}
                    onClick={(e) => handleSocialClick(e)}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="ml-4 lg:ml-6"
                  >
                    <Icon name="facebook" />
                    <span className="hidden">Facebook</span>
                  </a>
                )}
              </div>
            </div>
            {relatedCareers.length > 0 && (
              <div className="mt-12 md:mt-20 lg:mt-0 col-span-full lg:col-span-4 lg:col-start-10">
                <h2 className="mb-8 md:mb-12 text-2xl md:text-3xl font-bold">
                  Related vacancies
                </h2>
                <div className="grid sm:grid-cols-2 md:grid-cols-1 gap-4">
                  {relatedCareers.map(({ node: career }, i) => (
                    <CareerCard {...{ ...career, backgroundColour: "Grey" }} />
                  ))}
                </div>
                <LinkWithIcon
                  to="/careers"
                  icon="arrowRight"
                  color="mi-dark"
                  text="See all jobs"
                  className="mt-8 md:mt-12"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <StudioPerksList {...{ ...studioPerks }} />
    </>
  )
}

export default CareerBody
