import React, { useState } from 'react'
import cx from 'classnames'
import ReactMarkdown from 'react-markdown'

import LinkWithIcon from '../LinkWithIcon'

import './style.scss'

const StudioPerksList = ({
    heading,
    perks = [],
}) => {
    const [ active, setActive ] = useState(-1)

    return perks.length > 0 && (
        <div className="studio-perks-list py-12 md:py-16 xl:py-28 bg-bare relative overflow-hidden">
            <div className="container mx-auto relative px-10">
                <div className="grid grid-cols-1 md:grid-cols-14">
                    <div className="md:col-span-full xl:col-span-12 xl:col-start-2">
                        <h2 className="line-styled-heading max-w-sm md:max-w-md mb-12 md:mb-20 relative text-4xl sm:text-5xl lg:text-6xl 2xl:text-7xl font-extrabold uppercase"><ReactMarkdown children={heading} /></h2>
                        <div className="grid lg:grid-cols-3 lg:gap-10">
                            {perks.map(({ heading, icon, details }, i) => (
                                <div key={i} className={cx({ active: i === active })}>
                                    <div className="flex items-center lg:min-h-28 mb-8 pb-8 border-b border-dashed border-aldo cursor-pointer lg:cursor-default select-none" onClick={() => setActive((i === active) ? -1 : i)}>
                                        <img src={icon.url} className="w-12 md:w-20 h-12 md:h-20 mr-8" />
                                        <h3 className="studio-perks-list__subheading flex-1 text-2xl sm:text-3xl text-black font-light uppercase"><ReactMarkdown children={heading} /></h3>
                                        <LinkWithIcon icon={(active === i) ? 'angleUp' : 'angleDown'} clickHandler={() => true} color="mi-dark" className="lg:hidden self-center" />
                                    </div>
                                    <ul className="studio-perks-list__details hidden lg:block mb-8 md:mb-12 lg:mb-0 styled-list -pt-4 lg:-pt-6 text-lg">
                                        {JSON.parse(details).map((detail, j) => (
                                            <li key={j}>{detail}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                        <span className="flex text-right justify-end italic text-xs mt-6 lg:mt-10 text-boulder">*Does not apply to Studio Orbital as roles are remote.</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudioPerksList
