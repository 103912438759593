import React from 'react'
import ReactMarkdown from 'react-markdown'

import LinkWithIcon from '../LinkWithIcon'

import './style.scss'

const CareerHero = ({
    title,
    application_url: applicationUrl,
}) => (
    <div className="career-hero relative bg-primary overflow-hidden">
        <div className="pt-32 pb-20 md:pb-32 md:pt-48 relative">
            <div className="container mx-auto px-10">
                <div className="grid xl:grid-cols-14">
                    <div className="xl:col-span-12 xl:col-start-2 relative z-20">
                        <h1 className="max-w-xs mb-8 text-4xl md:text-5xl lg:text-6xl text-white font-extrabold uppercase"><ReactMarkdown children={title} /></h1>
                        <LinkWithIcon to={applicationUrl} external icon="arrowRight" color="mi-light" text="Apply" />
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default CareerHero
